<div id="openIPO" *ngIf="screenWidth > 768">
  <div class="container marginLess">
    <h2 class="headingsTable">
      Trending :<i class="fa-solid fa-envelope-open-dollar"></i>
    </h2>
    <div class="row">
      <div class="col-md-12">
        <table class="table table-bordered table-hover">
          <thead class="tableColor">
            <tr>
              <th class="text-center">IPO</th>
              <th class="text-center">GMP Latest</th>
              <th class="text-center">IPO Price</th>
              <th class="text-center">Estimated Listing</th>
              <th class="text-center">IPO status</th>
              <th class="text-center">IPO Open Date</th>
              <th class="text-center">IPO Close Date</th>
              <th class="text-center">Listing Date</th>
              <th class="text-center">GMP Updated On</th>

              <th class="text-center">Expected Listing Gains</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr *ngFor="let ipo of activeIpoList">
              <td class="text-center">
                <a href="https://gmptoday.com/ipo/{{ ipo.id }}/syrma-ipo">{{
                  ipo.ipoName
                }}</a>
              </td>
              <td class="text-center">
                {{ ipo.ipoGmp | currency: "INR":"symbol" }}
                <i
                  *ngIf="ipo.gmpChange == 'increase' && ipo.ipoStatus == 'Open'"
                  class="fa-solid fa-arrow-up increase"
                ></i>
                <i
                  *ngIf="ipo.gmpChange == 'decrease' && ipo.ipoStatus == 'Open'"
                  class="fa-solid fa-arrow-down decrease"
                ></i>
              </td>
              <td class="text-center">
                {{ ipo.ipoPrice | currency: "INR":"symbol" }}
              </td>
              <td class="text-center">
                {{ estimatedListingPrice(ipo) | currency: "INR":"symbol" }}
                <div>
                  <span>(</span>
                  <span>{{ percentCalculate(ipo) | number: "1.1-2" }}</span
                  ><span>%</span><span>)</span>
                </div>
              </td>
              <td class="text-center">
                <span
                  [ngClass]="{
                    ' openStatus blink_me': ipo.ipoStatus === 'Open',
                    closedStatus: ipo.ipoStatus === 'Closed',
                    listedStatus: ipo.ipoStatus === 'Listed'
                  }"
                >
                  {{ ipo.ipoStatus }}</span
                >
              </td>
              <td class="text-center dateSize">
                {{ ipo.ipoOpenDate | date: "dd-MMM-y" }}
              </td>
              <td class="text-center dateSize">
                {{ ipo.ipoCloseDate | date: "dd-MMM-y" }}
              </td>
              <td class="text-center dateSize">
                {{ ipo.ipoListDate | date: "dd-MMM-y" }}
              </td>
              <td class="text-center">
                {{ ipo.ipoGmpLastUpdated | date: "dd-MMM-y" }}
              </td>
              <td class="text-center">
                <button
                  type="button"
                  [disabled]=""
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  (click)="assignIpoToModal(ipo)"
                >
                  Check
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ ipoInModal?.ipoName }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <label for="exampleInputEmail1" class="form-label"
                >Number of lots you want to buy</label
              >
              <input
                [(ngModel)]="noOfLots"
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                min="1"
                max="{{ ipoInModal?.maxLot }}"
                oninput="validity.valid||(value=1);"
              />
              <div class="form-text">
                Minimum lot is <span>1,</span> Maximum lots is
                <span>{{ ipoInModal?.maxLot }}</span>
              </div>
            </div>
            <div>
              <br />
              <table class="table table-bordered">
                <thead class="">
                  <tr>
                    <th>Lots Applied</th>
                    <th>Amount of 1 lot</th>
                    <th>Total Amount</th>
                    <th>Return</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ noOfLots }}</td>
                    <td>{{ amountOfOneLot() | currency: "INR":"symbol" }}</td>
                    <td>{{ totalInvested() | currency: "INR":"symbol" }}</td>
                    <td>
                      {{
                        calculateExpectedGain()
                          | currency: "INR":"symbol":"1.0-0"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <h6 class="gain">
                Expected Gain
                <span>
                  {{
                    calculateExpectedGain() - totalInvested()
                      | currency: "INR":"symbol":"1.0-0"
                  }}</span
                >
              </h6>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="openIPO" *ngIf="screenWidth <= 768">
  <div class="container">
    <div class="row">
      <h3 class="headingsTable">Trending:</h3>
      <div class="col-sm-12" *ngFor="let ipo of activeIpoList">
        <div class="card" style="width: 22rem">
          <h5 class="card-header">
            <a href="https://gmptoday.com/ipo/{{ ipo.id }}/syrma-ipo">{{
              ipo.ipoName
            }}</a>
          </h5>
          <div class="card-body">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>GMP</td>
                  <td>
                    {{ ipo.ipoGmp | currency: "INR":"symbol" }}
                    <i
                      *ngIf="
                        ipo.gmpChange == 'increase' && ipo.ipoStatus == 'Open'
                      "
                      class="fa-solid fa-arrow-up increase"
                    ></i>
                    <i
                      *ngIf="
                        ipo.gmpChange == 'decrease' && ipo.ipoStatus == 'Open'
                      "
                      class="fa-solid fa-arrow-down decrease"
                    ></i>
                  </td>
                </tr>
                <tr>
                  <td>IPO Price</td>
                  <td>{{ ipo.ipoPrice | currency: "INR":"symbol" }}</td>
                </tr>
                <tr>
                  <td>Estimated Listing Price</td>
                  <td>
                    {{ estimatedListingPrice(ipo) | currency: "INR":"symbol" }}
                    <div>
                      <span>(</span>
                      <span>{{ percentCalculate(ipo) | number: "1.1-2" }}</span
                      ><span>%</span><span>)</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>IPO Status</td>
                  <td>
                    <span
                      [ngClass]="{
                        ' openStatus blink_me': ipo.ipoStatus === 'Open',
                        closedStatus: ipo.ipoStatus === 'Closed',
                        listedStatus: ipo.ipoStatus === 'Listed'
                      }"
                    >
                      {{ ipo.ipoStatus }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>IPO Open Date</td>
                  <td>{{ ipo.ipoOpenDate | date: "dd-MMM-y" }}</td>
                </tr>
                <tr>
                  <td>IPO Close Date</td>
                  <td>{{ ipo.ipoCloseDate | date: "dd-MMM-y" }}</td>
                </tr>
                <tr>
                  <td>IPO Listing Date</td>
                  <td>{{ ipo.ipoListDate | date: "dd-MMM-y" }}</td>
                </tr>
              </tbody>
            </table>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              (click)="assignIpoToModal(ipo)"
              [disabled]=""
            >
              Expected Listing Gains
            </button>
          </div>
        </div>
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ ipoInModal?.ipoName }}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div>
                  <label for="exampleInputEmail1" class="form-label"
                    >Number of lots you want to buy</label
                  >
                  <input
                    [(ngModel)]="noOfLots"
                    type="number"
                    class="form-control"
                    id="exampleInputEmail1"
                    min="1"
                    max="{{ ipoInModal?.maxLot }}"
                    oninput="validity.valid||(value=1);"
                  />
                  <div class="form-text">
                    Minimum lot is <span>1,</span> Maximum lots is
                    <span>{{ ipoInModal?.maxLot }}</span>
                  </div>
                </div>
                <div>
                  <br />
                  <table class="table table-bordered">
                    <thead class="">
                      <tr>
                        <th>Lots Applied</th>
                        <th>Amount of 1 lot</th>
                        <th>Total Amount</th>
                        <th>Return</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ noOfLots }}</td>
                        <td>
                          {{ amountOfOneLot() | currency: "INR":"symbol" }}
                        </td>
                        <td>
                          {{ totalInvested() | currency: "INR":"symbol" }}
                        </td>
                        <td>
                          {{
                            calculateExpectedGain()
                              | currency: "INR":"symbol":"1.0-0"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h6 class="gain">
                    Expected Gain
                    <span>
                      {{
                        calculateExpectedGain() - totalInvested()
                          | currency: "INR":"symbol":"1.0-0"
                      }}</span
                    >
                  </h6>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
