import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Ipo } from 'src/model/ipo';
import myData from '../../assets/ipoList.json';

@Component({
  selector: 'app-trending-ipo',
  templateUrl: './trending-ipo.component.html',
  styleUrls: ['./trending-ipo.component.css']
})
export class TrendingIpoComponent implements OnInit {

 
  ipoList!: Ipo[];
  screenWidth!: number;
  activeIpoList!:Ipo[]
  ipoInModal!:Ipo
  noOfLots:number=1

  constructor() { }
  // @ViewChild('exampleModal') exampleModal: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    // this.exampleModal.nativeElement.click();
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.ipoList = myData
    this.activeIpoList=this.ipoList.filter(ele=>ele.ipoStatus=='Open' || ele.ipoStatus=='Coming Soon' || ele.ipoStatus=='Closed')
  }
  assignIpoToModal(selectedIpo:Ipo){
    this.ipoInModal=selectedIpo
    this.noOfLots=1;
  }
  estimatedListingPrice(ipo:Ipo){
    let estimatedListingPrice;
    estimatedListingPrice=ipo?.ipoPrice+ ipo?.ipoGmp
    return estimatedListingPrice;

  }
  percentCalculate(ipo:Ipo){
    
    let estimatedListingPrice=this.estimatedListingPrice(ipo)
    let percent= ((estimatedListingPrice-ipo.ipoPrice )/ipo.ipoPrice  )*100
    return percent;
  }
  calculateExpectedGain(){
   let estimatedListingOfEachLot=this.estimatedListingPrice(this.ipoInModal) * this.ipoInModal?.sharesInEachLot;
   return this.noOfLots*estimatedListingOfEachLot

  }
  totalInvested(){
    return (this.ipoInModal?.ipoPrice *this.ipoInModal?.sharesInEachLot)* this.noOfLots;
  }
  amountOfOneLot(){
    return this.ipoInModal?.ipoPrice* this.ipoInModal?.sharesInEachLot
  }

}
