import { Component, HostListener, OnInit } from '@angular/core';
import myData from '../../assets/ipoList.json';
import { Ipo } from 'src/model/ipo';

@Component({
  selector: 'app-parent-view',
  templateUrl: './parent-view.component.html',
  styleUrls: ['./parent-view.component.css'],
})
export class ParentViewComponent implements OnInit {
  mobileView: boolean = false;
  desktopView: boolean = false;
  ipoList!: Ipo[];
  listedIpoList!: Ipo[];
  upcomingIpoList!: Ipo[];
  activeIpoList!: Ipo[];
  ipoInModal!: Ipo;
  noOfLots: number = 1;
  screenWidth!: number;

  constructor() {}
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.ipoList = myData;
    this.listedIpoList = this.ipoList.filter(
      (ele) => ele.ipoStatus == 'Listed'
    );
    this.upcomingIpoList = this.ipoList.filter(
      (ele) => ele.ipoStatus == 'Future'
    );
    this.activeIpoList = this.ipoList
      .filter(
        (ele) =>
          ele.ipoStatus == 'Open' ||
          ele.ipoStatus == 'Upcoming' ||
          ele.ipoStatus == 'Closed'
      )
      .reverse();
  }
  assignIpoToModal(selectedIpo: Ipo) {
    this.ipoInModal = selectedIpo;
    this.noOfLots = 1;
  }
  estimatedListingPrice(ipo: Ipo) {
    let estimatedListingPrice;
    estimatedListingPrice = ipo?.ipoPrice + ipo?.ipoGmp;
    return estimatedListingPrice;
  }
  percentCalculate(ipo: Ipo) {
    let estimatedListingPrice = this.estimatedListingPrice(ipo);
    let percent = ((estimatedListingPrice - ipo.ipoPrice) / ipo.ipoPrice) * 100;
    return percent;
  }
  calculateExpectedGain() {
    let estimatedListingOfEachLot =
      this.estimatedListingPrice(this.ipoInModal) *
      this.ipoInModal?.sharesInEachLot;
    return this.noOfLots * estimatedListingOfEachLot;
  }
  totalInvested() {
    return (
      this.ipoInModal?.ipoPrice *
      this.ipoInModal?.sharesInEachLot *
      this.noOfLots
    );
  }
  amountOfOneLot() {
    return this.ipoInModal?.ipoPrice * this.ipoInModal?.sharesInEachLot;
  }
}
